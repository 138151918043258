<template>
	<div class="setting-pay">
		<div class="setting-pay-title">开通支付渠道</div>

		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px">
			<el-form-item label="企业主体类型:" prop="MerchantType">
				<el-radio-group v-model="ruleForm.MerchantType" style="margin-left:10px;">
					<el-radio v-for="item in options" :label="item.value" :value="item.value" :key="item.value">
						{{item.label}}
					</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="经营所在地省份:" prop="BusinessPositionProvinceCode">
				<el-select v-model="ruleForm.BusinessPositionProvinceCode" @change="provinceChange"
					placeholder="请选择经营所在地省份" style="width: 350px;">
					<el-option v-for="item in provinceList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="经营所在地城市:" prop="BusinessPositionCityCode">
				<el-select v-model="ruleForm.BusinessPositionCityCode" @change="cityChange" placeholder="经营所在地城市"
					style="width: 350px;">
					<el-option v-for="item in cityList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="经营所在地县区:" prop="BusinessPositionDistrictCode">
				<el-select v-model="ruleForm.BusinessPositionDistrictCode" @change="areaChange" placeholder="经营所在地县区"
					style="width: 350px;">
					<el-option v-for="item in areaList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
		</el-form>

		<div class="submit">
			<el-button @click="handleBack" style="margin-right: 30px;">稍后开通</el-button>
			<el-button type="primary" @click="handleSubmit">立即开通</el-button>
		</div>

		<el-dialog title="提示" :visible.sync="visible" width="600px">
			<div>
				<div style="line-height: 24px;padding: 0 10px;">
					微信端支付渠道已经成功开通，扫描下方签约码，完成签约，客户进入商城即可支付订单啦~
					<span style="color: #F56C6C;">（如果没有完成签约，客户将不能支付哦）</span>
				</div>
				<img style="display: block;width: 290px;margin: 10px auto;" src="http://cdn.dkycn.cn/images/melyshop/20210609162404.png"></img>
			</div>
			<div slot="footer" style="text-align: center;">
				<el-button type="primary" @click="handleBack">我已完成签约</el-button>
			</div>
		</el-dialog>


	</div>
</template>

<script>
	import {
		adapayMerchantOpen,
		adapayMerchantInfo
	} from '@/api/TurnTomySelf';

	import axios from 'axios'

	export default {
		components: {},
		data() {
			return {
				visible: false,
				
				options: [{
					value: 1,
					label: '政府机构'
				}, {
					value: 2,
					label: '国营企业'
				}, {
					value: 3,
					label: '私营企业'
				}, {
					value: 4,
					label: '外资企业'
				}, {
					value: 5,
					label: '个体工商户'
				}
				// , {
				// 	value: 8,
				// 	label: '小微企业'
				// },
				],

				provinceList: [],
				cityList: [],
				areaList: [],

				ruleForm: {
					MerchantType: '',
					BusinessPositionProvinceCode: '',
					BusinessPositionProvince: '',
					BusinessPositionCityCode: '',
					BusinessPositionCity: '',
					BusinessPositionDistrictCode: '',
					BusinessPositionDistrict: ''
				},
				rules: {
					MerchantType: [{
						required: true,
						trigger: 'change',
						message: '请选择企业主体类型'
					}],
					BusinessPositionProvinceCode: [{
						required: true,
						trigger: 'change',
						message: '请选择经营所在地省份'
					}],
					BusinessPositionCityCode: [{
						required: true,
						trigger: 'change',
						message: '请选择经营所在地城市'
					}],
					BusinessPositionDistrictCode: [{
						required: true,
						trigger: 'change',
						message: '请选择经营所在地县区'
					}],
				},

				address:null,
			}
		},
		created() {
			this.getDataList();
		},
		methods: {
			//获取地址数据
			async getDataList() {
				var that = this
				await axios.get('http://cdn.dkycn.cn/JSON/melyshop/ruzhu.json?v=' + new Date().getTime(), {})
					.then(function(response) {
						var dt = response.data;
						var array = [];
						for (let item in dt) {

							var obj = {
								value: dt[item].val,
								label: item,
								children: []
							};

							var array1 = dt[item].items;
							for (let item1 in array1) {
								var obj1 = {
									value: array1[item1].val,
									label: item1,
									children: []
								};

								var array2 = array1[item1].items;

								for (let item2 in array2) {
									var obj2 = {
										value: array2[item2],
										label: item2
									};
									obj1.children.push(obj2)
								}
								obj.children.push(obj1)
							}

							array.push(obj)
						}
						that.provinceList = array
					})
					.catch(function(error) {})
				this.getaddress()
			},
			provinceChange(val) {
				// console.log(val)
				this.provinceList.map(item => {
					if (item.value == val) {
					// console.log(val)
						this.ruleForm.BusinessPositionProvince = item.label;
						this.cityList = item.children;
						// console.log(this.provinceList)
						// console.log(item.children)
					// this.ruleForm.BusinessPositionCityCode = item.children[0].value;
					// this.cityChange(item.children[0].value)
					}
				})
					this.ruleForm.BusinessPositionCityCode = ''
					this.ruleForm.BusinessPositionDistrictCode = '';
			},
			cityChange(val) {
				this.cityList.map(item => {
					if (item.value == val) {
						this.ruleForm.BusinessPositionCity = item.label;
						this.areaList = item.children;
						// this.ruleForm.BusinessPositionCityCode = item.children[0].value;
						// this.areaChange(item.children[0].value)
					}
				})
				this.ruleForm.BusinessPositionDistrictCode = '';
			},
			areaChange(val) {
				this.areaList.map(item => {
					if (item.value == val) {
						this.ruleForm.BusinessPositionDistrict = item.label;
					}
				})
			},

			handleBack() {
				this.$router.replace({
					name: 'settingPay'
				})
			},
			handleSubmit() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.save()
					}
				});
			},
			async save() {
				// console.log(this.ruleForm)
				const res = await adapayMerchantOpen(this.ruleForm);

				if (res.IsSuccess) {
					this.visible = true;
				}
				
			},

			async getaddress(){
				let that = this
				let result = await adapayMerchantInfo()
				// console.log(result.Result)
					that.address = result.Result
						that.ruleForm.BusinessPositionProvinceCode = that.address.BusinessAddressProvinceCode
						that.provinceChange(that.address.BusinessAddressProvinceCode)
						that.ruleForm.BusinessPositionCityCode = that.address.BusinessAddressCityCode
						that.cityChange(that.address.BusinessAddressCityCode)
						that.ruleForm.BusinessPositionDistrictCode = that.address.BusinessAddressDistrictCode
						that.areaChange(that.address.BusinessAddressDistrictCode)
			}
		}
	}
</script>

<style lang="less" scoped>
	.setting-pay {

		padding: 10px 10px 30px;
		background: #fff;

		.setting-pay-title {
			font-size: 14px;
			padding: 10px;
			border-bottom: 1px solid #eee;
			margin-bottom: 15px;
		}

		.submit {
			position: fixed;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 60px;
			background-color: #fff;
			border-top: 1px solid #ddd;

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
</style>
